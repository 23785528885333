import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/ReviewCard.css';

const ReviewCard = () => {
    const { subUrl } = useParams();
    const [vendor, setVendor] = useState(null);
    const [config, setConfig] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        reviewerInfo: {
            name: '',
            email: '',
            phone: ''
        },
        responses: {}
    });

    // Check if user is logged in
    const isLoggedIn = !!localStorage.getItem('token');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthToken(token);
        }
    
        const fetchVendorConfig = async () => {
            try {
                const response = await axios.get(`/api/reviews/${subUrl}`);
                setVendor(response.data.vendor);
                setConfig(response.data.config);
            } catch (error) {
                setError('Unable to load review form');
            } finally {
                setIsInitialLoading(false);
            }
        };
    
        fetchVendorConfig();
    }, [subUrl]);

    const handleInputChange = (type, questionId, value) => {
        if (type === 'reviewer') {
            setFormData(prev => ({
                ...prev,
                reviewerInfo: {
                    ...prev.reviewerInfo,
                    [questionId]: value
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                responses: {
                    ...prev.responses,
                    [questionId]: value
                }
            }));
        }
    };

    const renderStarRating = (questionId, currentRating = 0) => {
        return (
            <div className="star-rating">
                {[1, 2, 3, 4, 5].map((star) => (
                    <button
                        key={star}
                        type="button"
                        className={`star ${star <= currentRating ? 'active' : ''}`}
                        onClick={() => handleInputChange('response', questionId, star)}
                    >
                        ★
                    </button>
                ))}
            </div>
        );
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setIsLoading(true);

    //     try {
    //         await axios.post(`/api/reviews/${subUrl}/submit`, formData);
    //         setIsSubmitted(true);
    //     } catch (error) {
    //         setError('Error submitting review. Please try again.');
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const token = localStorage.getItem('token');
    
        try {
            // Set token again just before submission
            if (token) {
                setAuthToken(token);
            }
            
            console.log('Submitting review with auth token:', !!token);
            
            const response = await axios.post(`/api/reviews/${subUrl}/submit`, formData);
            console.log('Review submission response:', response.data);
            setIsSubmitted(true);
        } catch (error) {
            console.error('Review submission error:', error);
            setError('Error submitting review. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isInitialLoading) {
        return (
            <div className="loading-overlay-review">
                <div className="loading-spinner-review"></div>
            </div>
        );
    }

    if (error) {
        return <div className="review-card-error">{error}</div>;
    }

    if (isSubmitted) {
        return (
            <div className="review-card-success">
                <div className="check-icon">✓</div>
                <h2>Thank You!</h2>
                <p>Your feedback has been submitted successfully.</p>
                <p>We appreciate you taking the time to share your thoughts.</p>
                <Link to={`/vendor/${subUrl}`} className="return-link">
                    Go to the {vendor.business_name} Loyalty Page
                </Link>
            </div>
        );
    }

    return (
        <div className="review-card">
            <h1 className="review-card-title">Review Card</h1>
            <div className="review-card-header">
                {vendor.logo_url && (
                    <img src={vendor.logo_url} alt={vendor.business_name} className="vendor-logo-review" />
                )}
                <h2>{vendor.business_name}</h2>
                <div className="vendor-info-review">
                    <p>{vendor.address}</p>
                    <p>{vendor.phone}</p>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="review-form">
                {!isLoggedIn && (
                    <div className="reviewer-info-section">
                        <input
                            type="text"
                            placeholder="Your Name"
                            required
                            value={formData.reviewerInfo.name}
                            onChange={(e) => handleInputChange('reviewer', 'name', e.target.value)}
                        />
                        <input
                            type="email"
                            placeholder="Your Email"
                            required
                            value={formData.reviewerInfo.email}
                            onChange={(e) => handleInputChange('reviewer', 'email', e.target.value)}
                        />
                        <input
                            type="tel"
                            placeholder="Your Phone (optional)"
                            value={formData.reviewerInfo.phone}
                            onChange={(e) => handleInputChange('reviewer', 'phone', e.target.value)}
                        />
                    </div>
                )}

                {config.questions.map((question) => (
                    <div key={question.id} className="question-container-review">
                          <label>
                        {question.text}
                        {question.required && <span className="required-field">*</span>}
                         </label>
                        {question.type === 'star_rating' ? (
                            renderStarRating(question.id, formData.responses[question.id])
                        ) : (
                            <textarea
                                className="textarea-review"
                                placeholder="Your answer..."
                                value={formData.responses[question.id] || ''}
                                onChange={(e) => handleInputChange('response', question.id, e.target.value)}
                                required={question.required}
                            />
                        )}
                    </div>
                ))}

                {isSubmitting && (
                    <div className="loading-overlay-review">
                        <div className="loading-spinner-review"></div>
                    </div>
                )}

                <button type="submit" className="submit-button-review" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit Review'}
                </button>
            </form>
        </div>
    );
};

export default ReviewCard;