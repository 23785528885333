import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../styles/SubscriptionResult.css';

const SubscriptionResult = () => {
    const { result } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/vendor/dashboard');
        }, 5000);
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="subscription-result-container">
            {result === 'success' ? (
                <div className="subscription-success-message">
                    <div className="subscription-success-icon">✓</div>
                    <h1>Payment Received</h1>
                    <p>Thank you for your subscription. We're processing your payment.</p>
                    <p>You will be redirected to your dashboard shortly...</p>
                    <div className="subscription-details-summary">
                        <h3>Your Premium Benefits Include:</h3>
                        <ul>
                            <li>Unlimited customers</li>
                            <li>Advanced analytics and reporting</li>
                            <li>Priority email and WhatsApp support</li>
                            <li>Custom branding options</li>
                            <li>Automated customer engagement tools</li>
                        </ul>
                    </div>
                </div>
            ) : (
                <div className="subscription-error-message">
                    <div className="subscription-error-icon">!</div>
                    <h1>Payment Not Completed</h1>
                    <p>The subscription payment was not completed.</p>
                    <div className="subscription-error-actions">
                        <button 
                            onClick={() => navigate('/vendor/dashboard')}
                            className="subscription-dashboard-button"
                        >
                            Return to Dashboard
                        </button>
                        <button 
                            onClick={() => navigate('/subscription/checkout')}
                            className="subscription-retry-button"
                        >
                            Try Again
                        </button>
                    </div>
                    <p className="subscription-support-text">
                        Need help? Contact our support team at{' '}
                        <a href="mailto:support@my-local.co.za">support@my-local.co.za</a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default SubscriptionResult;