import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/VendorLanding.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VendorLanding = () => {

    useEffect(() => {
        document.body.className = 'landing-page-body';
        return () => {
          document.body.className = '';
        };
      }, []);

  return (
    <div className="ml-landing-page">
      <header className="ml-header">
        <nav className="ml-nav">
          <div className="ml-nav-content">
            <div className="ml-nav-left">
              <div className="ml-logo-container">
                <Link to="/" className="ml-logo-link" aria-label="My-Local Logo">
                  <img src="images/mylocal205.png" alt="My-Local Logo" className="ml-logo" />
                  {/* <span className="ml-logo-text"></span> */}
                </Link>
              </div>
            </div>
            <div className="ml-nav-menu">
              <a href="#features" className="ml-nav-link">Features</a>
              <a href="#how-it-works" className="ml-nav-link">How It Works</a>
              <a href="#pricing" className="ml-nav-link">Pricing</a>
            </div>
            <div className="ml-nav-cta">
              <Link to="/vendor/register" className="ml-sign-up-btn">Sign Up Free</Link>
            </div>
          </div>
        </nav>
      </header>

      <main className="ml-main">
        <section className="ml-hero">
          <div className="ml-hero-gradient"></div>
          <div className="ml-hero-content">
            <div className="ml-hero-text">
              <h1 className="ml-hero-title">
                <span>Turn First-Time Customers</span>
                <span className="ml-highlight">Into Regular Visitors</span>
              </h1>
              <p className="ml-hero-description">
                Launch your digital loyalty program in minutes. No app downloads required. Just simple, effective customer retention.
              </p>
              <div className="ml-hero-cta">
                <Link to="/vendor/register" className="ml-cta-button">
                  Start Free
                </Link>
              </div>
            </div>
          </div>
          <div className="ml-hero-image">
            <img 
              src="https://images.unsplash.com/photo-1556742049-0cfed4f6a45d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" 
              alt="Happy customers in a cafe" 
            />
          </div>
        </section>

        <section id="features" className="ml-features">
          <div className="ml-features-header">
            <h2 className="ml-section-tag">Features</h2>
            <p className="ml-section-title">Everything you need to retain customers</p>
            <p className="ml-section-description">
              Simple tools that help you turn first-time visitors into regular customers.
            </p>
          </div>

          <div className="ml-features-grid">
            {[
              {
                icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                ),
                title: "Digital Loyalty Cards",
                description: "Replace paper cards with digital ones. Customers access their rewards instantly through their phone's browser."
              },
              {
                icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                ),
                title: "Instant Setup",
                description: "Get your loyalty program up and running in minutes. No technical skills required."
              },
              {
                icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                ),
                title: "Analytics",
                description: "Track customer visits and gain insights to improve your business."
              },
              {
                icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                ),
                title: "Automated Reminders",
                description: "Send timely reminders to bring customers back to your business."
              }
            ].map((feature, index) => (
              <div key={index} className="ml-feature-card">
                <div className="ml-feature-icon">{feature.icon}</div>
                <h3 className="ml-feature-title">{feature.title}</h3>
                <p className="ml-feature-description">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section id="how-it-works" className="ml-how-it-works">
          <div className="ml-section-header">
            <h2 className="ml-section-tag">How It Works</h2>
            <p className="ml-section-title">Three simple steps to loyalty</p>
            <p className="ml-section-description">Get your loyalty program up and running in no time.</p>
          </div>

          <div className="ml-steps">
            {[
              { number: "1", title: "Sign Up", description: "Create your account and customize your loyalty program." },
              { number: "2", title: "Share", description: "Invite customers to join your loyalty program." },
              { number: "3", title: "Grow", description: "Watch your customer base and revenue grow." }
            ].map((step, index) => (
              <div key={index} className="ml-step">
                <div className="ml-step-number">{step.number}</div>
                <h3 className="ml-step-title">{step.title}</h3>
                <p className="ml-step-description">{step.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section id="pricing" className="ml-pricing">
          <div className="ml-section-header">
            <h2 className="ml-section-tag">Pricing</h2>
            <p className="ml-section-title">Simple, transparent pricing</p>
            <p className="ml-section-description">Choose the plan that's right for your business</p>
          </div>

          <div className="ml-pricing-plans">
            <div className="ml-plan-card ml-basic">
              <div className="ml-plan-header">
                <h3>Basic</h3>
                <div className="ml-plan-price">
                  R0<span>/month</span>
                </div>
                <p>Perfect for small businesses just getting started</p>
              </div>
              <ul className="ml-plan-features">
                <li>Up to 25 customers</li>
                <li>Basic analytics</li>
                <li>Email support</li>
              </ul>
              <Link to="/vendor/register" className="ml-plan-cta">Start for free</Link>
            </div>

            <div className="ml-plan-card ml-pro">
              <div className="ml-plan-header">
                <h3>Pro</h3>
                <div className="ml-plan-price">
                  R600<span>/month</span>
                </div>
                <p>For businesses ready to scale their loyalty program</p>
              </div>
              <ul className="ml-plan-features">
                <li>Unlimited customers</li>
                <li>Advanced analytics</li>
                <li>Priority support</li>
                <li>Custom branding</li>
              </ul>
              <Link to="#" className="ml-plan-cta">Upgrade to Pro</Link>
            </div>
          </div>
        </section>

        <section className="ml-final-cta">
          <h2>Ready to boost your customer loyalty?</h2>
          <p>Start your free trial today. No credit card required.</p>
          <Link to="/vendor/register" className="ml-cta-button-white">Sign up for free</Link>
        </section>
      </main>

      <footer className="ml-footer">
        <div className="ml-footer-content">
          <p>&copy; 2025 My Local, Inc. All rights reserved..</p>
          <a href="https://wa.me/27817501758" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                    <span className="whatsapp-text"> Chat with us</span>
                  </a>
        </div>
      </footer>
    </div>
  );
};

export default VendorLanding;