import React, { useState,  useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/VendorRegister.css';

export default function VendorRegister() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    businessName: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in as a vendor
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        if (payload.role === 'vendor') {
          // Check if vendor has completed setup before redirecting
          const checkVendorSetup = async () => {
            try {
              const response = await axios.get('/api/vendors/profile');
              if (response.data?.setup_completed) {
                // Only redirect if setup is complete
                navigate('/vendor/dashboard');
              } else {
                // Redirect to setup wizard if not complete
                navigate('/vendor/setup-wizard');
              }
            } catch (error) {
              // If profile fetch fails, vendor likely needs setup
              navigate('/vendor/setup-wizard');
            }
          };
          checkVendorSetup();
        } else if (payload.role === 'customer') {
          // Redirect customers to appropriate page
          navigate('/customer'); // or wherever customers should go
        }
      } catch (error) {
        // Invalid token, clear it
        localStorage.removeItem('token');
        setAuthToken(null);
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
  
    try {
      const result = await axios.post('/api/auth/register', {
        email: formData.email,
        password: formData.password,
        name: formData.businessName,
        role: 'vendor'
      });
  
      // Set auth token
      localStorage.setItem('token', result.data.token);
      setAuthToken(result.data.token);
      
      // Always redirect to setup wizard for new registrations
      navigate('/vendor/setup-wizard');
    } catch (err) {
      setError(err.response?.data?.error || 'Registration failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="vendor-register-container">
      <div className="register-card">
        <h1>Create Your Vendor Account</h1>
        <p className="subtitle">Start growing your business with digital loyalty programs</p>

        <form onSubmit={handleSubmit}>
          {error && <div className="error-message">{error}</div>}
          
          <div className="form-group">
            <input
              type="email"
              placeholder="Business Email"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <div className="password-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={formData.password}
                onChange={(e) => setFormData({...formData, password: e.target.value})}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="Business Name"
              value={formData.businessName}
              onChange={(e) => setFormData({...formData, businessName: e.target.value})}
              required
            />
          </div>

          <button type="submit" className="register-button" disabled={isLoading}>
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </button>
        </form>
      </div>
    </div>
  );
}