import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';
import { Download, ChevronDown, ChevronUp, Home, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import '../../styles/SubscriptionInvoices.css';

const SubscriptionInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedInvoice, setExpandedInvoice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          setAuthToken(token);
        }

        const response = await axios.get('/api/subscription/invoices');
        setInvoices(response.data);
      } catch (err) {
        setError('Failed to load invoices');
        console.error('Error fetching invoices:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-ZA', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR'
    }).format(amount);
  };

  const handleDownloadInvoice = async (invoiceId) => {
    try {
      const response = await axios.get(`/api/subscription/invoices/${invoiceId}/download`, {
        responseType: 'blob'
      });
      
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `invoice-${invoiceId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error downloading invoice:', err);
    }
  };

  if (loading) {
    return <div className="loading-spinner-sub">Loading...</div>;
  }

  if (error) {
    return <div className="error-message-sub">{error}</div>;
  }

  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <aside className="sidebar-t">
        <div className="sidebar-content-t">
          <button 
            onClick={() => navigate('/vendor/dashboard')}
            className="sidebar-item-t"
            title="Dashboard"
          >
            <LayoutDashboard size={24} />
          </button>
        </div>
      </aside>


    <div className="invoices-container">
      <h2>Subscription Invoices</h2>
      
      <div className="invoices-table-container">
        <table className="invoices-table">
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <React.Fragment key={invoice.id}>
                <tr>
                  <td>{invoice.invoice_number}</td>
                  <td>{formatDate(invoice.created_at)}</td>
                  <td>{formatAmount(invoice.amount)}</td>
                  <td>
                    <span className={`status-badge ${invoice.status}`}>
                      {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                    </span>
                  </td>
                  <td>
                    <div className="action-buttons-sub">
                      <button
                        onClick={() => handleDownloadInvoice(invoice.id)}
                        className="download-button"
                        title="Download Invoice"
                      >
                        <Download className="icon" />
                      </button>
                      <button
                        onClick={() => setExpandedInvoice(expandedInvoice === invoice.id ? null : invoice.id)}
                        className="expand-button"
                      >
                        {expandedInvoice === invoice.id ? 
                          <ChevronUp className="icon" /> : 
                          <ChevronDown className="icon" />
                        }
                      </button>
                    </div>
                  </td>
                </tr>
                {expandedInvoice === invoice.id && (
                  <tr className="expanded-row">
                    <td colSpan="5">
                      <div className="invoice-details">
                        <div className="details-section-sub">
                          <h4>Billing Details</h4>
                          <p>{invoice.details.companyName}</p>
                          <p>{invoice.details.billingAddress}</p>
                          <p>{invoice.details.city}, {invoice.details.postalCode}</p>
                          <p>{invoice.details.country}</p>
                        </div>
                        <div className="details-section-sub">
                          <h4>Payment Details</h4>
                          <p>Payment Method: {invoice.payment_method}</p>
                          <p>Transaction ID: {invoice.transaction_id}</p>
                          <p>VAT Number: {invoice.details.vatNumber || 'N/A'}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        
        {invoices.length === 0 && (
          <div className="no-invoices">No invoices found</div>
        )}
      </div>
    </div>
    </div>
  );
};

export default SubscriptionInvoices;