import React, { useState, useEffect, useRef } from 'react';
import './InfoTooltip.css';

const InfoTooltip = ({ 
  text, 
  position = 'top',
  className = '',
  variant = 'default',
  theme = 'dark',
  children
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) &&
          triggerRef.current && !triggerRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
      if (isMobile) {
        document.body.style.overflow = 'hidden';
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = '';
    };
  }, [showTooltip, isMobile]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setShowTooltip(!showTooltip);
    }
  };

  const tooltipClasses = [
    'tooltip-content',
    theme,
    isMobile ? '' : position,
    className
  ].filter(Boolean).join(' ');

  return (
    <span className="info-tooltip-container" ref={triggerRef}>
      <button
        className={`info-icon ${variant}`}
        onClick={() => setShowTooltip(!showTooltip)}
        onKeyPress={handleKeyPress}
        aria-label="Information"
        tabIndex={0}
      >
        {children || <span className="info-circle">i</span>}
      </button>
      {showTooltip && (
        <div 
          className={tooltipClasses}
          ref={tooltipRef}
          role="tooltip"
          aria-live="polite"
        >
          {theme === 'light' && (
            <button 
              className="tooltip-close"
              onClick={() => setShowTooltip(false)}
              aria-label="Close tooltip"
            />
          )}
          {text}
          {!isMobile && <div className="tooltip-arrow" />}
        </div>
      )}
    </span>
  );
};

export default InfoTooltip;