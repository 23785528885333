import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import '../styles/VendorSubscriptions.css';

const ManualSubscriptionControl = ({ vendor, onUpdate }) => {
    const [isManual, setIsManual] = useState(vendor.is_manual_subscription || false);
    const [notes, setNotes] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        setIsManual(vendor.is_manual_subscription || false);
    }, [vendor.is_manual_subscription]);

    const handleUpdate = async () => {
        setIsUpdating(true);
        try {
            await axios.post('/api/admin/vendor/subscription', {
                vendorId: vendor.id,
                isManual,
                notes
            });
            setNotes('');
            onUpdate();
        } catch (error) {
            console.error('Failed to update subscription:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <div className="subscription-control">
            <div className="manual-subscription">
                <label>
                    <input
                        type="checkbox"
                        checked={isManual}
                        onChange={(e) => setIsManual(e.target.checked)}
                    />
                    Manual Override
                </label>
                {isManual && (
                    <input
                        type="text"
                        placeholder="Add note (optional)"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                )}
            </div>
            <div className="subscription-status">
                Status: {vendor.subscription_status || 'free'}
                {isManual && ' (Manual)'}
            </div>
            <button
                onClick={handleUpdate}
                disabled={isUpdating}
            >
                {isUpdating ? 'Updating...' : 'Update Status'}
            </button>
        </div>
    );
};

const VendorSubscriptions = () => {
    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchVendors = async () => {
        try {
            const response = await axios.get('/api/vendors/all');
            setVendors(response.data);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVendors();
    }, []);

    if (loading) return <div>Loading vendors...</div>;

    return (
        <div className="vendor-subscriptions">
            <h2>Vendor Subscriptions</h2>
            <div className="vendors-list">
                {vendors.map(vendor => (
                    <div key={vendor.id} className="vendor-item">
                        <h3>{vendor.business_name}</h3>
                        <ManualSubscriptionControl 
                            vendor={vendor} 
                            onUpdate={fetchVendors}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VendorSubscriptions;