import React, { useState, useCallback, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { setAuthToken, isTokenValid } from '../utils/auth';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import '../styles/BookingSection.css';

function BookingSection({ vendorId }) {
  const [newBooking, setNewBooking] = useState({
    date: '',
    time: '',
    number_of_people: 1,
    special_requests: ''
  });
  const [errors, setErrors] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const queryClient = useQueryClient();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isTokenValid()) {
      setAuthToken(token);
      setIsLoggedIn(true);
    } else {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
    }
  }, []);

  const getBookingRowClass = (bookingDateTime) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const [bookingDate] = bookingDateTime.split('T');
    const bookingDateObj = new Date(bookingDate);
    bookingDateObj.setHours(0, 0, 0, 0);
  
    if (bookingDateObj.getTime() === today.getTime()) {
      return 'current-day-booking';
    } else if (bookingDateObj > today) {
      return 'upcoming-booking';
    }
    return '';
  };


  const { data: bookings, isLoading, error } = useQuery(
    ['bookings', vendorId],
    async () => {
      if (!isTokenValid()) {
        setIsLoggedIn(false);
        return null;
      }
      try {
        const response = await axios.get(`/api/bookings/customer/${vendorId}`);
        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          setIsLoggedIn(false);
          return null;
        }
        throw error;
      }
    },
    {
      refetchInterval: 30000,
      enabled: isLoggedIn,
      retry: false,
      onError: (error) => {
        console.error('Query error:', error.response?.data || error.message);
      }
    }
  );

  const bookingMutation = useMutation(
    (bookingData) => axios.post(`/api/bookings/customer/${vendorId}`, bookingData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bookings', vendorId]);
        setNewBooking({
          date: '',
          time: '',
          number_of_people: 1,
          special_requests: ''
        });
        setSuccessMessage('Booking request submitted successfully');
        setErrorMessage('');
      },
      onError: (error) => {
        setErrorMessage('Failed to submit booking request: ' + (error.response?.data?.error || 'Unknown error'));
        setSuccessMessage('');
      }
    }
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBooking(prev => ({ ...prev, [name]: value }));
  };

  // const validateForm = useCallback(() => {
  //   const errors = {};
  //   const currentDate = new Date();
  //   const selectedDate = new Date(`${newBooking.date}T${newBooking.time}`);

  //   if (selectedDate <= currentDate) {
  //     errors.date = 'Booking date must be in the future';
  //   }

  //   if (newBooking.number_of_people < 1) {
  //     errors.number_of_people = 'Number of people must be at least 1';
  //   }

  //   setErrors(errors);
  //   return Object.keys(errors).length === 0;
  // }, [newBooking]);

  const validateForm = useCallback(() => {
    const errors = {};
  
    if (!newBooking.date || !newBooking.time) {
      errors.datetime = 'Please select both date and time';
    } else {
      // Create date string in YYYY-MM-DD format to avoid timezone issues
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      // Split the time to get hours and minutes
      const [hours, minutes] = newBooking.time.split(':');
      const selectedDate = new Date(newBooking.date);
      selectedDate.setHours(hours, minutes, 0, 0);
  
      if (selectedDate.getTime() <= today.getTime()) {
        errors.datetime = 'Booking date and time must be in the future';
      }
    }
  
    if (!newBooking.number_of_people || newBooking.number_of_people < 1) {
      errors.number_of_people = 'Number of people must be at least 1';
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, [newBooking]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    // Create a proper datetime string without timezone conversion
    const bookingData = {
      ...newBooking,
      // date_time: `${newBooking.date}T${newBooking.time}:00` 
      date: newBooking.date,
      time: newBooking.time

    };
  
    bookingMutation.mutate(bookingData);
  }, [validateForm, bookingMutation, newBooking]);

  const renderContent = () => {
    if (!isLoggedIn) {
      return (
        <div className="booking-section">
          <h2>Bookings</h2>
          <p>Please log in to make a booking.</p>
        </div>
      );
    }

    if (isLoading) return <div className="loader">Loading...</div>;
    if (error) return <div className="error-message">Error loading bookings. Please try again later.</div>;

  return (
    <div className="booking-section">
    {isLoading && <div className="loader">Loading...</div>}
      <h2>Make a Booking</h2>
      <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="date">Date:</label>
            <input
              type="date"
              id="date"
              name="date"
              value={newBooking.date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="time">Time:</label>
            <input
              type="time"
              id="time"
              name="time"
              value={newBooking.time}
              onChange={handleInputChange}
              required
            />
          </div>
          {errors.datetime && <p className="error-message">{errors.datetime}</p>}
          
          <div>
            <label htmlFor="number_of_people">Number of People:</label>
            <input
              type="number"
              id="number_of_people"
              name="number_of_people"
              value={newBooking.number_of_people}
              onChange={handleInputChange}
              min="1"
              required
            />
            {errors.number_of_people && <p className="error-message">{errors.number_of_people}</p>}
          </div>
          
          <div>
            <label htmlFor="special_requests">Special Requests:</label>
            <textarea
              id="special_requests"
              name="special_requests"
              value={newBooking.special_requests}
              onChange={handleInputChange}
            />
          </div>

        

          <button type="submit" disabled={bookingMutation.isLoading}>
            {bookingMutation.isLoading ? 'Submitting...' : 'Submit Booking Request'}
          </button>
          
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>

      <h3>Your Bookings</h3>
      {bookings && bookings.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>People</th>
              <th>Status</th>
              <th>Special Requests</th>
            </tr>
          </thead>
          <tbody>
          {bookings.map(booking => {
             const [dateStr, timeStr] = booking.date_time.split('T');
             const formattedDate = new Date(dateStr).toLocaleDateString();
             const formattedTime = timeStr.substring(0, 5); // Get HH:mm format

            return (
              <tr key={booking.id} className={getBookingRowClass(booking.date_time)}>
                <td data-label="Date">{formattedDate}</td>
                <td data-label="Time">{formattedTime}</td>
                <td data-label="People">{booking.number_of_people}</td>
                <td data-label="Status">{booking.status}</td>
                <td data-label="Special Requests">{booking.special_requests || 'None'}</td>
              </tr>
            );
          })}
        </tbody>
        </table>
      ) : (
        <p>You have no bookings yet.</p>
      )}
    </div>
  );
}

return renderContent();
}

export default BookingSection;