import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';
import '../../styles/SubscriptionCheckout.css';

const SubscriptionCheckout = () => {
    const [formData, setFormData] = useState({
        companyName: '',
        registrationNumber: '',
        vatNumber: '',
        billingAddress: '',
        city: '',
        postalCode: '',
        country: 'South Africa',
        contactName: '',
        contactEmail: '',
        contactPhone: ''
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthToken(token);
            fetchVendorData(token);
        }
    }, []);

    const fetchVendorData = async (token) => {
        try {
            const response = await axios.get('/api/vendors/profile');
            const vendorData = response.data;
            setFormData(prev => ({
                ...prev,
                companyName: vendorData.business_name || '',
                contactPhone: vendorData.phone || '',
                billingAddress: vendorData.address || '',
                contactEmail: vendorData.email || ''  // Added this to pre-fill email
            }));
        } catch (err) {
            console.error('Error fetching vendor data:', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        try {
            const token = localStorage.getItem('token');
            if (token) {
                setAuthToken(token);
            }

            const response = await axios.post('/api/subscription/create', {
                invoiceDetails: formData
            });

            // No need to remove sessionId since it's used by PayFast
            window.location.href = response.data.paymentUrl;
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to process checkout');
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className="subscription-checkout-container">
            <div className="subscription-checkout-header">
                <h1>Premium Subscription</h1>
                <p className="subscription-subtitle">Unlock unlimited growth potential for your business</p>
            </div>

            <div className="subscription-details-card">
                <div className="subscription-price">R600/month</div>
                <ul className="subscription-features">
                    <li>Unlimited customers signups for loyalty programs</li>
                    <li>Advanced analytics and reporting</li>
                    <li>Review system with unlimited submissions</li>
                    <li>Priority email and WhatsApp support</li>
                    <li>Custom branding options</li>
                    <li>Automated customer engagement tools</li>
                </ul>
            </div>

            <form onSubmit={handleSubmit} className="subscription-checkout-form">
                {/* Your existing form groups remain the same */}
                <div className="subscription-form-group">
                    <label htmlFor="companyName">Company Name *</label>
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="subscription-form-group">
                    <label htmlFor="registrationNumber">Registration Number</label>
                    <input
                        type="text"
                        id="registrationNumber"
                        name="registrationNumber"
                        value={formData.registrationNumber}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="subscription-form-group">
                    <label htmlFor="vatNumber">VAT Number</label>
                    <input
                        type="text"
                        id="vatNumber"
                        name="vatNumber"
                        value={formData.vatNumber}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="subscription-form-group full-width">
                    <label htmlFor="billingAddress">Billing Address *</label>
                    <textarea
                        id="billingAddress"
                        name="billingAddress"
                        value={formData.billingAddress}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="subscription-form-group">
                    <label htmlFor="city">City *</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="subscription-form-group">
                    <label htmlFor="postalCode">Postal Code *</label>
                    <input
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="subscription-form-group">
                    <label htmlFor="contactName">Contact Person Name *</label>
                    <input
                        type="text"
                        id="contactName"
                        name="contactName"
                        value={formData.contactName}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="subscription-form-group">
                    <label htmlFor="contactEmail">Contact Email *</label>
                    <input
                        type="email"
                        id="contactEmail"
                        name="contactEmail"
                        value={formData.contactEmail}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="subscription-form-group">
                    <label htmlFor="contactPhone">Contact Phone *</label>
                    <input
                        type="tel"
                        id="contactPhone"
                        name="contactPhone"
                        value={formData.contactPhone}
                        onChange={handleInputChange}
                        required
                    />
                </div>


                <div className="subscription-form-actions">
                    <button 
                        type="submit" 
                        className="subscription-checkout-button"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="button-content">
                                <span className="spinner"></span>
                                Processing...
                            </span>
                        ) : (
                            'Proceed to Secure Payment'
                        )}
                    </button>
                    
                    <p className="secure-payment-note">
                        🔒 Secure payment powered by PayFast
                    </p>
                </div>

                {error && (
                    <div className="subscription-error-message">
                        {error}
                    </div>
                )}
            </form>
        </div>
    );
};

export default SubscriptionCheckout;