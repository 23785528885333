import React, { useEffect } from 'react';

const SupportChat = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = "//code.tidio.co/vk5bakkbtw4jmhlsry3tv0lddrnctgp0.js";
    script.async = true;
    script.defer = true;
    
    // Add unique ID to prevent duplicate installations
    if (!document.getElementById('tidio-chat-widget')) {
      script.id = 'tidio-chat-widget';
      document.body.appendChild(script);
    }

    // Cleanup function to remove the script on unmount
    return () => {
      // Remove the script element
      const existingScript = document.getElementById('tidio-chat-widget');
      if (existingScript) {
        existingScript.remove();
      }
      
      // Also remove the Tidio iframe if it exists
      const tidioFrame = document.getElementById('tidio-chat-iframe');
      if (tidioFrame) {
        tidioFrame.remove();
      }
    };
  }, []);

  return null;
};

export default SupportChat;