import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';
import { useQuery, useMutation, useQueryClient } from 'react-query';

function BookingManagement() {
  const [notificationEmail, setNotificationEmail] = useState('');
  const [ccEmails, setCcEmails] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const queryClient = useQueryClient();
  const ITEMS_PER_PAGE = 10;
  const [approvingBookings, setApprovingBookings] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const { data: emailSettings, isLoading: isLoadingEmailSettings } = useQuery(
    'emailSettings',
    () => axios.get('/api/bookings/vendor/email-settings').then(res => res.data)
  );

  const { data: bookingsData, isLoading: isLoadingBookings, error: bookingsError } = useQuery(
    ['bookings', currentPage],
    () => axios.get(`/api/bookings/vendor?page=${currentPage}&limit=${ITEMS_PER_PAGE}`).then(res => res.data),
    {
      keepPreviousData: true,
    }
  );

  // const { data: customerBookings, isLoading: isLoadingCustomerBookings } = useQuery(
  //   ['customerBookings', selectedCustomer],
  //   () => selectedCustomer ? axios.get(`/api/bookings/vendor/customer/${selectedCustomer}`).then(res => res.data) : null,
  //   {
  //     enabled: !!selectedCustomer,
  //   }
  // );

  const { data: customerBookings, isLoading: isLoadingCustomerBookings } = useQuery(
    ['vendorCustomerBookings', selectedCustomer],
    () => selectedCustomer ? axios.get(`/api/bookings/vendor/customer/${selectedCustomer}`).then(res => res.data) : null,
    {
      enabled: !!selectedCustomer,
    }
  );

  const updateEmailSettingsMutation = useMutation(
    (newSettings) => axios.post('/api/bookings/vendor/email-settings', newSettings),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('emailSettings');
        alert('Email settings updated successfully');
      },
      onError: (error) => {
        alert('Failed to update email settings: ' + (error.response?.data?.error || 'Unknown error'));
      }
    }
  );

  const approveBookingMutation = useMutation(
    (bookingId) => axios.post(`/api/bookings/vendor/approve/${bookingId}`),
    {
      onMutate: (bookingId) => {
        setApprovingBookings(prev => ({ ...prev, [bookingId]: true }));
      },
      onSuccess: (_, bookingId) => {  // Use underscore to indicate unused parameter
        queryClient.invalidateQueries('bookings');
        queryClient.invalidateQueries('customerBookings');
        setApprovingBookings(prev => ({ ...prev, [bookingId]: false }));
      },
      onError: (error, bookingId) => {
        alert('Failed to approve booking: ' + (error.response?.data?.error || 'Unknown error'));
        setApprovingBookings(prev => ({ ...prev, [bookingId]: false }));
      }
    }
  );

  const debouncedSetEmailSettings = useCallback(
    debounce((settings) => {
      setNotificationEmail(settings.notification_email);
      setCcEmails(settings.cc_emails);
    }, 300),
    []
  );

  const debouncedHandleEmailSettingsUpdate = useCallback(
    debounce(async (newNotificationEmail, newCcEmails) => {
      updateEmailSettingsMutation.mutate({ 
        notificationEmail: newNotificationEmail, 
        ccEmails: newCcEmails 
      });
    }, 300),
    [updateEmailSettingsMutation]
  );

  useEffect(() => {
    if (emailSettings) {
      debouncedSetEmailSettings(emailSettings);
    }
  }, [emailSettings, debouncedSetEmailSettings]);

  const handleEmailSettingsUpdate = (e) => {
    e.preventDefault();
    debouncedHandleEmailSettingsUpdate(notificationEmail, ccEmails);
  };

  const handleApproveBooking = async (bookingId) => {
    if (window.confirm('Are you sure you want to approve this booking?')) {
      approveBookingMutation.mutate(bookingId);
    }
  };

  if (isLoadingEmailSettings || isLoadingBookings) return <div className="loader">Loading...</div>;
  if (bookingsError) return <div className="error-message">{bookingsError.message}</div>;

  return (
<div className="booking-management">
      <h2>Booking Management</h2>
      <form onSubmit={handleEmailSettingsUpdate}>
        <div className="form-group">
          <label htmlFor="notificationEmail">Notification Email:</label>
          <input
            type="email"
            id="notificationEmail"
            value={notificationEmail}
            onChange={(e) => setNotificationEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="ccEmails">CC Emails (comma-separated):</label>
          <input
            type="text"
            id="ccEmails"
            value={ccEmails}
            onChange={(e) => setCcEmails(e.target.value)}
          />
        </div>
        <button 
          type="submit" 
          className="btn btn-primary"
          disabled={updateEmailSettingsMutation.isLoading}
        >
          {updateEmailSettingsMutation.isLoading ? 'Updating...' : 'Update Email Settings'}
        </button>
      </form>

      <h3>Booking Requests</h3>
      <table>
        <thead>
          <tr>
            <th>Customer</th>
            <th>Date</th>
            <th>Time</th>
            <th>People</th>
            <th>Special Requests</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {/* Check if bookingsData and bookingsData.bookings are defined and not empty */}
        {bookingsData?.bookings?.length > 0 ? (
          bookingsData.bookings.map(booking => {
            const [dateStr, timeStr] = booking.date_time.split('T');
            const formattedDate = new Date(dateStr).toLocaleDateString();
            const formattedTime = timeStr.substring(0, 5); // Get HH:mm format

            return (
              <tr key={booking.id}>
                <td>
                  <button onClick={() => setSelectedCustomer(booking.customer_id)}>
                    {booking.customer_name}
                  </button>
                </td>
                <td>{formattedDate}</td>
                <td>{formattedTime}</td>
                <td>{booking.number_of_people}</td>
                <td>{booking.special_requests || 'None'}</td>
                <td>{booking.status}</td>
                <td>
                  {booking.status === 'pending' && (
                    <button
                      onClick={() => handleApproveBooking(booking.id)}
                      disabled={approvingBookings[booking.id]}
                    >
                      {approvingBookings[booking.id] ? 'Approving...' : 'Approve'}
                    </button>
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr><td colSpan="6">No bookings available.</td></tr>
        )}
        </tbody>
      </table>
      <div className="pagination">
        <button 
          className="btn btn-primary"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{currentPage} / {bookingsData?.totalPages}</span>
        <button 
          className="btn btn-primary"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, bookingsData?.totalPages || 1))} 
          disabled={currentPage === bookingsData?.totalPages}
        >
          Next
        </button>
      </div>

      {selectedCustomer && (
        <div className="customer-bookings">
          <h3>Customer Bookings</h3>
          {isLoadingCustomerBookings ? (
            <div className="loader">Loading customer bookings...</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>People</th>
                  <th>Special Requests</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {/* Check if customerBookings is defined and not empty */}
                {customerBookings?.length > 0 ? (
                    customerBookings.map(booking => (
                    <tr key={booking.id}>
                        <td>{new Date(booking.date_time).toLocaleDateString()}</td>
                        <td>{new Date(booking.date_time).toLocaleTimeString()}</td>
                        <td>{booking.number_of_people}</td>
                        <td>{booking.special_requests || 'None'}</td>
                        <td>{booking.status}</td>
                    </tr>
                    ))
                ) : (
                    <tr><td colSpan="4">No bookings available for this customer.</td></tr>
                )}
                </tbody>
            </table>
          )}
          <button onClick={() => setSelectedCustomer(null)}>Close Customer Bookings</button>
        </div>
      )}
    </div>
  );
}

export default BookingManagement;